import React, { FC } from 'react';
import { ICreditCardComparisons } from './CreditCardComparisons.def';
import PageHeader from '../../../PageHeader/PageHeader';
import Programs from '../../../Programs/Programs';
import PaginationV2 from '../../../PaginationV2/PaginationV2';

const CreditCardComparisons: FC<ICreditCardComparisons> = ({
  pageHeader,
  pagination,
  creditCardComparisons,
}) => {
  return (
    <div className="credit-card-comparisons">
      <PageHeader {...pageHeader} />
      <div className="container">
        <Programs {...creditCardComparisons} />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default CreditCardComparisons;
