import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getPrograms, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import { MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import CreditCardComparisons from '../../components/CreditCards/components/CreditCardComparisons/CreditCardComparisons';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface ICreditCardComparisonsTemplateData {
  posts: Queries.WpPostConnection;
}

const CreditCardComparisonsTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<ICreditCardComparisonsTemplateData>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];
  const title = 'Credit Card Comparisons';

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const programs = getPrograms(posts);

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  return (
    <HTPageWrapper category="Credit Cards, Comparisons" title={title} location={location}>
      <CreditCardComparisons
        pageHeader={{
          title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/credit-cards/',
              text: 'Credit Cards',
            },
            {
              link: '/credit-cards/compare-credit-cards/',
              text: 'Credit Card Comparisons',
            },
          ],
          subTitle:
            'Our industry leading financial experts provide detailed comparisons of top credit cards from a wide range of issuers, to help you understand which card is right for you.',
        }}
        creditCardComparisons={{
          programs,
        }}
        pagination={{
          currentPageIndex: currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          basePath: '/credit-cards/compare-credit-cards',
          numberOfPages: totalNumberOfPages,
        }}
      />
    </HTPageWrapper>
  );
};

export default CreditCardComparisonsTemplate;

export const Head = ({ location, data }: PageProps<ICreditCardComparisonsTemplateData>) => {
  const pageTitle = 'Compare Credit Cards with UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Our detailed credit card comparisons give you all the information you need to pick the right card for you. Browse 100s of comparisons, from our finance experts.';
  const includeCollectionPageType = true;
  let pathname = location.pathname;
  if (!pathname.endsWith('/')) pathname += '/';
  const pageNumber = pathname.includes('page') ? parseInt(pathname.slice(-2, -1)) : 1;
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Compare Credit Cards', slug: 'compare-credit-cards' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'Compare Credit Cards - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query CreditCardComparisonsPosts($start: Int, $limit: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: $limit
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
